import React from 'react';
import Layout from '../layouts/layout'

function Terms() {
    return (
        <div>
            <Layout headerClass="bg-black h-[122px] relative">
                <div className='flex justify-center'>

                    <div className="container info-content py-20">



                    At The Urban Writers, we value our customers and their satisfaction is our top priority. Our dedicated team of talented writers is committed to delivering exceptional work, and we aim to consistently provide outstanding customer service.

In the spirit of high-end customer service, The Urban Writers offers a streamlined revision process that makes it easy to ensure your finished product is exactly what  you envisioned when placing your order with us.

Our revision policy ensures that order requirements are adhered to, and furthermore, it ensures that quality controls, such as editing and proofreading, have been properly conducted. However, it ultimately remains the customer’s responsibility to engage with their freelancer during the creation of their project to ensure that it is to their liking and that it matches their expectations. Our revision policy is designed to work hand in hand with you! We believe that your active participation is key to ensuring the success of the revision process.

Revisions and Our Revision Policy
We know how busy our customers are. We want our policies to be easy to understand and we don’t want reading them to take up too much of your time. Please find a point-form summary of our revision policy below. If you need more information, you can, of course, continue reading to review the policy in its entirety.

Policy Overview/Summary
To ensure your freelancer has the resources and information needed to execute your project to your satisfaction, it is mandatory that all order requirements be provided prior to work starting on the project
It is best practice (and our recommendation) to follow along as the freelancer works on the project. Provide guidance and feedback along the way to help the freelancer meet your expectations and ensure all requirements are met. This is not considered a revision. Asking for changes while the order is in progress (if reasonable and in line with the original requirements that were provided) ensures that any revisions that follow will complete the final touches and bring your order to perfection. If a freelancer says you must wait until the revision stage and/or refuses to make changes in-line with the order’s requirements, please escalate to our project admin team by email to support@dibbly.com.
Our standard revisions (those included with each applicable package) ensure the customer’s original outlined requirements are adhered to and met
Standard revisions do not include the addition of new content. New content includes anything that was not in the requirements/outline and/or communicated prior to the freelancer starting the project.
Revision requests must be made within the provided time frame. The time frame will be visible on each order page as it is moved into an approval and review stage. Approval times vary from 10 days to 5 days, and are reduced with each new revision requested on applicable packages (for more information about what is included in your package, please contact our Customer Success team at support@dibbly.com)
Each single revision consists of two parts:
Approval time: This is the period with a set time frame (indicated as a countdown on the order page) when the customer can review their order to decide which revisions are needed.
Revision time: When you click Request Revision, the revision time begins. This period is not timed, but is limited to one round of Document Comments. The writer or editor will resubmit the order for review after addressing the Doc Comments, which will start a new approval countdown timer.
Our quick Draft Approval stage explainer video can be found here: Platform Update: Draft Approval Stage
Revision requests for changes outside the scope of the project can be made, however, there will be a fee based on the word count and/or time needed to effect the changes. Out-of-scope revision fees are in place to compensate the freelancer for their work and to cover any processing fees. For more information relating to the rates for out-of-scope revisions, please contact our Customer Success team at support@dibbly.com
Customers must ensure they are reviewing their projects during the writing and editing phases. The Urban Writers cannot entertain any complaints originating from orders where customers gave their approval during the Customer Approval phase or allowed this time to lapse with no response (in exceptional cases, the team will work with customers to ensure requirements are met)
If a freelancer refuses to complete revisions in-line with the order’s requirements, please discuss your concerns with them and escalate to our project admin team by email to support@dibbly.com if needed
For writing, editing and outline projects, each review of the product or “round” of Document Comments will constitute one (1) revision and will need to be made within the package’s indicated timeframe. The writer or editor will resubmit the order for review after addressing the requested changes/revisions
Please note that narration, design, and illustration revisions do not allow for the complete re-creation of the project at hand, but rather allows for amendments to the existing submission. For example, a narrator would not be able to re-record an entire project or chapter as a revision. However, they could re-record a section of a chapter or piece of the project if initial requirements were not met. Each change requested of an illustrator, designer, or narrator will constitute one revision and will need to be made within the package’s indicated timeframe
The following requests are not permitted as part of our standard revision process:

Deleting content that is in line with the original requirements
Requesting new or additional content that was not originally stipulated or provided at the start of the project
Rewriting the content on your own and asking your writer and/or editor to paste this content into their document. Instead, your project will be provided to you as a fully editable Word/.docx file for download upon fulfillment and you can make your changes there
Rearranging content or moving images around if there were no requirements or instructions given to the freelancer regarding the structure of the order or the inclusion of images
Sending screenshots of changes or lists with page numbers on which you require revisions. It is mandatory to make use of our Document Comments system and/or the order page chat to request revisions
Requesting custom formatting that was not mentioned in the order's original requirements
Requesting a change in tone or content if no tone or content requirements were stipulated when the order was placed and no further instructions were given in this regard prior to its completion
Requesting changes to the document based on third party AI detection tools used, or based on the speculation of AI tools being used in the content creation process. While these tools may prove useful in certain contexts, their use does not guarantee accuracy or completeness of any content, and no warranty or representation is made as to its accuracy, completeness, timeliness, reliability or suitability for any particular purpose. For this reason, writers cannot be asked to make changes in accordance with their findings.
Full Policy Details
Our Revision Policy is in place to ensure customers have control over the outcome(s) of their project(s). We believe that a customer should get exactly what they paid for, and we believe our revision policy empowers them to get exactly that!

At The Urban Writers, your project really is your project, and you get a say in it. If a product produced by a freelancer on our Platform does not meet the requirements provided by the customer when placing the order, the customer must communicate this to their freelancer and request a revision while the project is still ongoing. Our freelancers take pride in their work and as such, they are happy to revise their work for you if you submit your revision request in a timely manner and ensure that it adheres to this Revision Policy. Please note: all requests for changes and/or revisions must be made while the project is still ongoing. Requests for changes cannot be made once the project has been fulfilled and/or has passed through the applicable review and approval phase.

Customers who give their approval during the Customer Approval phase, or who let this time lapse without response, and who do not request any revisions, waive their right to any further complaints or action regarding the project in question once the Customer Approval window has closed or lapsed. The Urban Writers cannot entertain any complaints originating from orders where customers gave their approval during the Customer Approval phase or allowed this time to lapse with no response.

Each package has its own revision count which displays the number of available revisions. Information about our packages’ revision counts can be found in their product descriptions on our website. We provide revisions within five (5) to ten (10) days of the order going into the Customer Approval Phase (“Draft Approval” and “Waiting for Customer Approval”) on our Platform. The length of the Customer Approval phase will depend on the ordered package and the number of revisions completed thus far (the provided review time decreased with each revision request on all applicable packages except Rising, which only includes one (1) revision. If revisions are not requested during the customer approval phase and/or the customer gives their approval or the review time lapses, no further revisions will be available. Additional changes can be made to a project after the Customer Approval phase has lapsed at an additional cost.

Additional out-of-scope revisions are dependent on freelancer availability. If you have questions about the revisions available for a specific package, please feel free to reach out to support@dibbly.com.

It is important to stipulate your expectations and requirements when placing the order to ensure that your freelancer is able to deliver the kind of content you have in mind. Vague instructions are not recommended unless the customer is proceeding with the intention of allowing their freelancer to take full creative control of the project at hand.

For writing projects, revisions come into play at the end of the writing stage once the product has been submitted by the writer for review and the status has been updated to “Draft Approval” and the timer is displayed at the top of the page. If the customer wants any revisions to the written content, they must request them at this point. Revisions to the editing will apply once the editor submits the order for review and the status has been updated to “Waiting for Customer Approval.” The customer may request revisions directly in the platform using the Document Comments and/or Chat to ensure that the requirements that were set when the order was placed have been met. Please note that customers can also communicate expected changes to their freelancers during the writing (or editing, designing, illustration, and/or narration) stage and before the review/approval phase begins, this will help minimize delays.

For outline projects, customers have the option of collaborative or non-collaborative projects. During collaborative outlines, customers are encouraged to work closely with freelancers to put together a thorough and detailed outline that brings their vision to life. Providing detailed requirements and following along as the outline takes shape are essential to ensuring the outline matches your plans for your manuscript. Once major chapter outlines or plot points have been completed and approved, any further major changes to them will incur an additional fee to compensate the freelancer.

During non-collaborative outline projects, customers are encouraged to provide as much detail and information to the freelancer upfront, so that the freelancer can ensure they create an outline that meets all your requirements.

Revisions for outlines come into play during the “Waiting for Customer Approval" stage, and all revisions must be in line with original requirements provided to the outliner at the beginning of the project, or within a reasonable time during the outline creation phase. Requests for major plot changes, reorganisation, new directions or new content will incur additional fees during the revision stage.

It is not considered a revision or a revision request to review your order while it’s in progress, to ask for changes while the order is in progress (if they are reasonable and in line with the original requirements that were provided), or to provide feedback or direction to your freelancer during the completion of your order. Customers may clarify their expectations of their freelancers and their orders to ensure that the final product meets the requirements that were provided when it was placed.

It is important to note that even while a project is in progress, major changes to the original requirements provided when the project was started by a freelancer will incur additional costs, as freelancers commit to projects based on the original requirements for that project.

The Urban Writers highly recommend staying in contact with your freelancer and regularly reviewing your order to ensure that it is to your liking. Additional instructions or requirements pertaining to the order’s creation may be provided to the freelancer after their start date if such a request is:

A) Reasonable
B) In line with the original requirements provided when the freelancer started the project
C) In line with what is provided by the package you’ve purchased, and
D) Made at a reasonable point in the order’s creation
Requests/requirement changes after the project has begun:

A) Must be agreed to by the freelancer
B) Will be invoiced for if these changes require rewrites to content that has already been written at the time of the requested changes and that is in line with the original requirements
C) May require a reallocation if the freelancer assigned to the order is not comfortable working on the project with different requirements
Please note that narration, design, and illustration revisions do not allow for the complete recreation of the project at hand, but rather amendments to the existing submission. For example, a narrator would not be able to re-record an entire project as a revision. However, they could be asked to re-record a certain chapter or piece of the project. Each change requested of an illustrator, designer, or narrator will constitute one revision and the request for such a change will need to be made within the package’s indicated timeframe. In terms of writing and editing orders, each review of the product or “round” of Document Comments will constitute one (1) revision (additional revisions will depend on the purchased package) and will need to be made within the package’s indicated timeframe.

The following requests are not permitted as revision requests:

Deleting content that is in line with the original requirements
Requesting new or additional content that was not originally stipulated or provided at the start of the project
Rewriting the content on your own and asking your writer and/or editor to paste this content into their document
Rearranging content or moving images around if there were no requirements or instructions given to the freelancer regarding the structure of the order or the inclusion of images
Sending screenshots of changes or lists with page numbers on which you require revisions. It is highly recommended to make use of our Document Comments system to request revisions
Requesting custom formatting that was not mentioned in the order's original requirements
Requesting a change in tone or content if no tone or content requirements were stipulated when the order was placed and requested by a freelancer and no further instructions were given in this regard during its completion
Requesting changes to the document based on third party AI detection tools used, or based on the speculation of AI tools being used in the content creation process. While these tools may prove useful in certain contexts, they are not always accurate and writers cannot be asked to make changes in accordance with their findings.
Please note that The Urban Writers reserves the right to intervene in situations where our revision process or policy is being abused or misused in terms of the requested changes and the timeframe in which they take place.

The customer is always welcome to make changes personally to any content they receive from us after the order has been fulfilled and before publishing. Customers fully and wholly own the intellectual property rights to fulfilled orders they have purchased from us and thus have full rights to modify or change the content as they see fit before publication.

If no revisions are requested during the Customer Approval phase and the order is instead approved (or the package's indicated approval timeframe expires), it is understood that the order is of satisfactory quality and that no revisions are required. If the Customer Approval timeframe expires with no revisions requested, the order will become available to download and be considered Fulfilled. Revisions requested after the review/approval timeframe has ended and the order has been Fulfilled will be subject to additional charges and may be affected by your freelancer’s availability. The cost of additional revisions will be determined by the Order Fulfillment Team on a case-by-case basis.

Our freelancers are eager to ensure that they deliver the order of your dreams! Our revision process is in place to assist them in doing so. For more information regarding the revision process and policies, please follow this link: https://theurbanwriters.com/blogs/publishing/how-request-revisions-ghostwritten-services?_pos=1&_sid=c92e45797&_ss=r

If you have any questions or concerns about an ongoing order, please reach out to support@dibbly.com, our team is always happy to assist. Producing world class content is our goal, and we will happily help you to ensure that your content is just that: world class.

If you’d like to find out more about placing an order with us, or if you’d like help in placing an order, please reach out to our Customer Success Team at support@dibbly.com.

Additional Revision Fees
The fee for out-of-scope or post-fulfillment revisions is dependent on the word count and/or time requirements to complete the changes. In order to ensure customers receive top-notch content and that freelancers using our Platform are fairly compensated for their work, we have implemented some special rates for out-of-scope revisions.

It is important to note that standard revisions (those included in our various packages, as applicable) are intended to ensure the original/outlined requirements are met. Provided the revisions are in-line with our policy and are requested within the provided review/approval time, you should not need to purchase any additional revisions.

The intention of our optional revision add-ons is to accommodate requests that do not fall under our Revision Policy, are being requested after prior approval was given, the review/approval timer lapsed, and/or the order has been fulfilled.

For out-of-scope revisions requested during the writing stage but prior to the editing stage, the following rates are used to determine the total cost (but they are subject to change):

$3.60/100 words - Finance, Tech, and Business Package
$2.73/100 words - Premium and Fiction Package
$2.58/100 words - Top Package
$2.39/100 words - Urban Package
$1.74/100 words - Rising Package
For additional revision requests that require re-editing as well, there would be an additional fee for the editor, depending on the level of editing required (proofreading/Essential Editing, Line Editing, Developmental Editing, Quality Assurance Editing, Quality Control Editing, etc.) Our admin team can assist with specific pricing/quotes in these cases.

Refunds and Our Refund Policy
We know how busy our customers are. We want our policies to be easy to understand and we don’t want reading them to take up too much of your time. Please find a point form summary of our revision policy below. If you need more information, you can, of course, continue reading to review the policy in its entirety.

In the case of a refund and/or cancellation request, your first step should be to contact our Customer Success Team via email at support@dibbly.com.

Policy Overview/Summary
Due to the nature of our digital products and services, we do not provide refunds on any fulfilled orders
To ensure freelancers are fairly compensated for their work, we do not provide full refunds on orders that a freelancer has begun working on on our Platform. This includes work that has been completed but not yet fulfilled and work that has been started, but is not yet complete
Full refunds can only be issued in instances where the project has not yet commenced. If any work on your project has started, please note that only a partial refund may be available and that the amount of the refund will depend on the freelancer’s progress
Refund requests are handled on a case-by-case basis
We prioritize solutions over refunds. We care about every order on our Platform. Should all efforts to resolve the issue(s) be exhausted, the team will submit the refund request for management approval
Circumstances in which a refund/partial refund may be provided include, but are not limited to:
Force majeure or acts of God that impact the freelancer’s timely commencement of the project (if they are delayed in starting the project and a replacement freelancer cannot be found)
Force majeure or acts of God that impact the customer’s ability to be available in terms of supervising the project at hand (and/or are unable to purchase Managed Services for their project)
For personal reasons that arise when your order has not yet been allocated and/or started at the time of the refund request
Refunds take between five (5) to fifteen (15) days to reflect on the customer’s end. This depends on the customer’s financial institution, we release funds as soon as an approved refund is processed on our end
You will receive an email notification confirming the refund has been processed (this will go to the email used on our Platform/at checkout)
Refunds can only be processed to the original payment method used when placing the order. We cannot refund a different card or PayPal account. If the original payment method is not available/no longer valid, a store credit can be provided in lieu of a refund
The Urban Writers does not offer refunds or revisions for difficulties or problems that customers may encounter with Third-Party Publishers like Amazon KDP
Full Policy Details
Our team will always work with the customer to resolve any issues to the best of our ability. However, we know that life happens and that there may be situations where a refund is required. We take pride in the level of service we provide to our customers across every touchpoint within The Urban Writers, this includes the handling of refund requests. If something went wrong, we want to do what we can to turn your experience around!

Due to the nature of the products we produce, we do not accept any returns on fulfilled orders. To ensure freelancers are fairly compensated for their work, we do not provide full refunds on orders that have been commenced, that is, where a portion of the work has been done by a freelancer on our Platform. This includes work that has been completed but not yet fulfilled and work that has been started, but is not yet complete.

Refund requests are handled on a case-by-case basis and we do our best to provide customers with top notch support and service. We believe that each customer deserves to be treated like our only customer, and strive to embody this ideology in every interaction.

In an effort to resolve any issues that lead to refund requests, our first course of action is always to offer order-related support. Our team will work with the customer to reset or reallocate orders as needed, follow up with allocated freelancers to address delays, initiate internal quality reviews, and provide the customer with options to move forward. We prioritize solutions over refunds as we believe it is more than a refunded transaction. We care about every customer order on our Platform. Each order represents an important project for that particular customer and we are in this industry because we are passionate about helping customers make their dreams a reality, whether it's an exciting new fictional story or an educational and engaging non fiction manuscript. Should all efforts to resolve the issue(s) be exhausted, the team will submit the refund request for approval.

Circumstances in which a refund/partial refund may be provided include, but are not limited to:

Force majeure or acts of God that impact the freelancer’s timely commencement of the project (if they are delayed in starting the project and a replacement freelancer cannot be found);
Force majeure or acts of God that impact the customer’s ability to be available to supervise the project at hand (and/or are unable to purchase Managed Services for their project); and
For personal reasons that arise when your order has not yet been allocated and/or started at the time of the refund request
Refunds, if processed, take between five (5) to fifteen (15) days to reflect on the customer’s end. This depends on the customer’s financial institution. We release funds as soon as an approved refund is processed on our end. Confirmation of approval for the refund request will be provided by the Customer Success Team. You will receive an email notification confirming the refund has been processed (this will go to the email used on our Platform/at checkout). Refunds can only be processed to the original payment method used when placing the order. We cannot refund a different card or PayPal account. If the original payment method is not available/no longer valid, a store credit can be provided in lieu of a refund.

Please note that PayPal does not allow refunds after 180 days have passed since the initial transaction date. The effect of this is that we are unable to offer monetary refunds to customers who have made payment via PayPal if such payment was made more than 180 prior. In cases where an acceptable refund is sought after the time frame allowed by PayPal, the customer will be offered credit with The Urban Writers to the value of the refund that was granted.

Revisions, Refunds, and Third-Party Publishers:
Refunds will only be considered within 90 days of the original purchase date. Requests for refunds must be submitted in writing to our customer service department. Refunds will be issued to the same payment method used for the original purchase without exception. Any refund requests made after the 90-day window will not be considered.

The Urban Writers does not offer refunds or revisions for difficulties or problems that customers may encounter with Third-Party Publishers like Amazon KDP. The Urban Writers does offer paid revisions to content if such difficulties are encountered, but does not accept responsibility or liability related to ensuring that orders placed with us meet the requirements of any/all Third-Party Publishers’ guidelines.

It is the customer’s responsibility to familiarize themselves with the rules and guidelines of any Third-Party Publisher they may want to make use of before commissioning work through our Platform and to communicate those needs upfront to their freelancers to determine whether they can be accommodated. The Urban Writers cannot be held responsible for any projects that do not comply with the standards and/or requirements of any Third-Party Publishers.

Third-Party Publishers, like Amazon KDP, have content guidelines in place which allow them to turn away customers who are attempting to publish content that is too generic or over-published.

Please see Section 6 of our Terms of Service for more information.

Additional non-returnable items:
Gift cards
Downloadable Digital products



                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default Terms;